import './App.css';

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AudioPlayer from './AudioPlayer';

function App() {
  const topic = 'PositiveNews';
  const [storyList, setStoryList] = useState(null);
  const [audioData, setAudioData] = useState(null);

  useEffect(() => {
    const fetchStories = async () => {
      try {
        const response = await axios.get(`/get-stories/${topic}`);
        setStoryList(response.data);
      } catch (error) {
        console.error('Error fetching story list:', error);
      }
    };

    fetchStories();
  }, []);

  function playStory(storyId) {
    const story = storyList.find(story => story._id === storyId);
    setAudioData(story);
  }

  return (
    <div style={{padding: '10px'}}>
      <h1>AI Stories for Kids</h1>
      {storyList && <div>
        {storyList.map((story, index) => (
          <div style={{marginTop: '10px'}} key={index}>
            <a href="#" onClick={() => playStory(story._id)}>
              {story.title}
            </a>
            { 
              audioData && (audioData._id === story._id) && <div id="overlay"><AudioPlayer audioData={audioData} setAudioData={setAudioData}></AudioPlayer> </div>
            }

          </div>
        ))}
      </div>
      }
    </div>
  );
}

// function App() {
//   const [audioData, setAudioData] = useState(null);
//   // const topic = 'reddit-UpliftingNews';
//   const topic = 'PositiveNews';

//   useEffect(() => {
//     const fetchAudioData = async () => {
//       try {
//         const response = await axios.get(`/listen-next/${topic}`);
//         setAudioData(response.data);
//       } catch (error) {
//         console.error('Error fetching audio data:', error);
//       }
//     };

//     fetchAudioData();
//   }, []);



//   return (
//     <div>
//       {audioData && <AudioPlayer audioData={audioData} />}
//     </div>
//   );
// }

// function App() {
//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route path="/start" element={<IntroScreen/>} />
//         <Route path="/topic/:topicName" element={<TopicIntro chatHost={chatHost} avatarUrl={avatarUrl}/>} />
//         <Route path="/work" element={<WorkTopicIntro chatHost={chatHost} avatarUrl={avatarUrl}/>} />
//         <Route path="/chat" element={<AvatarScreen />} />
//         <Route path="/sessions" element={<SessionTableAccordion chatHost={chatHost} />} />
//         <Route path="/profile" element={<PersonalityForm chatHost={chatHost} avatarUrl={avatarUrl}/>} />
//         <Route path="/question" element={<QuestionForm/>} />
//       </Routes>
//     </BrowserRouter>
//   )
// }

export default App;
